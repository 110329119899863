
// Product listing

.page-link.clickable {
    color: #000 !important;
}

li.page-item.active {
    .page-link.clickable {
        background-color: var(--uc-primarybuttoncolor);
        color: var(--uc-primarybuttontextcolor) !important;
    }
}

.accordion-button {
    color: #000;
}

// Content slider

.content-slider .carousel-caption.overlay-carousel-caption, .carousel-caption {
    background-image: linear-gradient(rgba(0,0,0,.6),rgba(0,0,0,.6)) !important;
}

// Product listing image constrain 

img.productImage.productCardImage {
    max-height: 12rem;
    width: auto;
}

// Footer

.footer-ribbon.footer-ribbon-two {
    border-top: 1px solid #fff;
}

footer .col-md-3 {
    margin-left: 0;
}

.footer {
    .container.flex-column.align-items-lg-end.align-items-md-start {
        padding: 0;
    }
    .col-lg-4.col-md-4.col-sm-12.footer-brandImage.flex-sm-row.flex-md-column {
        padding: 0;
    }
    ul.col-4 {
        div.linksTitle.px-0 {
            display: none;
        }
    }
}
// Header

a.nav-link::before {
    padding-right: 5px;
}

// Core overwrite

.footer {
    .container.d-flex.justify-s-b.pt-5.px-2.flex-wrap {
        padding-top: 0 !important;
    }
}
// Style overwrite - breadcrumb padding 

.container.p-4 {
    .row.d-flex.flex-wrap {
        .bg-light.px-4.py-3.pt-4 {
            padding-top: 15px !important;
            .d-block.mb-3.mb-lg-0.pt-lg-2 {
                padding-top: 0 !important;
            }
        }
    }
}

body {
    background-color: #ffffff;
}

img.productImage.image-zoom {
    max-height: 30rem;
    width: auto;
}